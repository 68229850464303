.main-pane {
  width: 100%;
}

@media (min-width: 769px) {
  .main-pane.withmenu {
    width: calc(100% - 200px);
  }
}

.elevation-20 {
  position: relative;
  z-index: 20;
}
