.flex-tbl {
  overflow: auto;
}

.icon-btn {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-tbl-row {
  display: flex;
  width: min-content;
  min-width: 100%;
  color: $grey_1;

  .cell {
    padding: 14px 0px;

    &.sticky-cell {
      background-color: white;
    }
  }
}

.flex-tbl-row-lnk:nth-child(even) > .flex-tbl-row {
  background-color: $grey_8;

  .cell.sticky-cell {
    background-color: $grey_8;
  }
}

.flex-tbl-row-lnk .flex-tbl-row.data {
  cursor: pointer;
}

.flex-tbl-row.data:hover {
  background-color: $blue_4;

  .cell {
    background-color: $blue_4 !important;
  }
}

@for $i from 1 through 100 {
  .flex-tbl-row-#{$i} .cell {
    width: calc(100% / #{$i});
    min-width: 150px;
    font-size: 14px;
    font-weight: normal;
    margin: auto 0px;

    @for $j from 0 through 4 {
      &.sticky-cell-#{$j} {
        position: sticky;
        margin: 0;
        margin: auto 0px;
        left: max(150px * #{$j}, 100% / #{$i} * #{$j});
      }
    }

    &.last-sticky {
      margin: 0px 15px 0px 0px;
      border-right: solid 1px #eee;
      min-height: 0px;
      padding: 0;
      display: flex;
      align-items: center;
      border-right: solid 1px #eee;
    }
  }
}

.page-nav-icon {
  display: flex;
  margin: 0px 8px;
}

.page-nav-icon-active {
  filter: $blue_2_filter;
  cursor: pointer;
}

.pagination-menu {
  display: flex;
  align-items: center;
  color: $grey_3;
  font-size: 12px;
  font-weight: normal;
  margin: 8px 0px;
}

.export-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $grey_3;
  margin: 8px 0px;
  width: 16px;
}

.pagination-menu .selector {
  margin: 0px 8px;
}

.flex-tbl .cell-label {
  text-align: right;
  width: 200px;
  display: inline-block;
  margin-right: 24px;
}

.flex-tbl .cell-header,
.flex-tbl .cell-label {
  color: $grey_3;
  font-size: 12px;
  font-weight: 500;
}

.cell-header {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  & .show-on-hover {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:hover .show-on-hover {
    opacity: 65%;
  }
}

.cell {
  text-align: left;
  padding-left: 5px;
}

@media (max-width: 1023px) {
  .flex-tbl-row {
    display: block;
    margin: 8px 0px;
    border-radius: 4px;
  }

  [class*='flex-tbl-row-'] .cell {
    width: 100%;
    text-align: left;
    padding: 0px 8px;
    font-size: 12px;
  }

  .flex-tbl-headers {
    display: none;
  }
}
