.selector {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 8px;
  border-radius: 8px;
  background-color: $grey_7;
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  .filter-bar .selector {
    margin-left: auto;
    margin-right: auto;
  }
}
