@import '~react-dates/lib/css/_datepicker.css';
@import '~@facilecomm/sbo-icon-font/index.css';

$roboto-font-path: '~roboto-fontface/fonts';
@import '~roboto-fontface/css/roboto/sass/roboto-fontface';

@import './color_palette.scss';

/* Redefine Bulma variables */
/* Background color */
$scheme-main: $page_bg;

.todo {
  color: lime;
  font-size: 30px !important;
  font-weight: 700;
  background-color: salmon;
}

@import '~bulma/bulma.sass';

/* Import main component styles */
@import './ShippingBI.scss';
@import './Navbar.scss';
@import './Menu.scss';

/* Import component styles */
@import './components/ViewComponents/ViewComponents.scss';
@import './components/FilterBar/FilterBar.scss';
@import './components/Select/Select.scss';
@import './components/ContentCard/ContentCard.scss';
@import './components/ContentCard/EvoCard.scss';
@import './components/ContentCard/TableCard.scss';
@import 'components/InfoBox/InfoBox';
@import 'components/ViewComponents/404/404';

html,
body,
#root,
.App {
  height: 100%;
  overflow: hidden;
}

.full_content {
  height: calc(100% - 56px);
  display: flex;
}

.App.asIframe .full_content {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.ContentPane {
  width: 100%;
  height: calc(100% - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 20px 12px 20px;
  flex-wrap: wrap;
  margin: 0;
  align-content: start;
}

.ContentPane.fullheight {
  height: calc(100%);
}

@media (max-width: 768px) {
  .ContentPane {
    padding: 6px 10px 6px 10px;
  }
}

.ContentPane .column {
  padding: 0;
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-link {
  cursor: pointer;
}

.breadcrumb .breadcrumb-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $grey_4;
  font-size: 12px;
  cursor: pointer;
}

.breadcrumb .breadcrumb-item:hover {
  color: $grey_3;
  font-size: 12px;
}

.breadcrumb img {
  margin-right: 8px;
}

.breadcrumb .lastcrumb {
  font-size: 12px;
  color: $grey_2;
}

.breadcrumb .selectable {
  user-select: all;
}

.chart-warning {
  font-size: 12px;
  color: $red;
}

.secondaryBtn {
  right: 0;
  position: absolute;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  outline: 1px solid $blue_5;
  border-radius: 8px;
  text-decoration: none;
  color: $blue_5;
  font-size: 14px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: $grey_9;
  }

  &:active {
    background-color: $grey_10;
  }

  &:focus {
    outline: 2px $blue_6 solid;
    outline-offset: -1px;
  }
}
