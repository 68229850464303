.filter-bar {
  position: relative;
  z-index: 12;
  width: 100%;
  height: 56px;
  background-color: $white;
  display: flex;
  padding-left: 40px;
  box-shadow: 0px 2px 3px 0px $transparent_black;
}

@media (max-width: 768px) {
  .filter-bar {
    height: 56px;
    padding-left: 0px;
  }
}

@media (max-width: 464px) {
  .filter-bar select {
    display: none;
  }
}

.order-sources-filter,
.order-origins-filter {
  margin-left: 8px;
}

.DateRangePicker td,
.DateRangePicker_picker td {
  vertical-align: middle !important;
  border: none;
}

.date-picker-wide {
  margin-left: 8px;
  display: flex;
}

.date-picker-wide .DateRangePicker {
  margin-top: auto;
  margin-bottom: auto;
}

.date-picker-small {
  margin: auto;
}

.DateInput input {
  font-weight: 500;
  font-size: 16px;
}

.DateInput_input__focused {
  border-bottom-color: $blue_3;
}

.CalendarDay__selected,
.CalendarDay__hovered_span:hover {
  background-color: $blue_3;
  border-radius: 0px 8px 8px 0px;
  color: $white;
  border: none;
}

.CalendarDay__selected:hover {
  background-color: darken($blue_3, 5);
  border: none;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background-color: lighten($blue_3, 50);
  border: none;
  color: $black;
}

.CalendarDay__selected_span:hover {
  background-color: lighten($blue_3, 45);
  border: none;
  color: $black;
}

.DayPickerNavigation_svg__vertical {
  height: 20px;
  width: 20px;
  margin-top: 8px;
}

.CalendarDay__default:hover {
  border: none;
}

.CalendarDay__selected_start {
  border-radius: 8px 0px 0px 8px;
}

.CalendarDay__selected_end {
  border-radius: 0px 8px 8px 0px;
  &.CalendarDay__selected_start {
    border-radius: 8px;
  }
}

.DayPicker_transitionContainer__vertical {
  height: 650px !important;
}

.DateInput_input {
  line-height: unset;
  padding: 7px 16px 6px;
}

.DateInput {
  border-radius: 8px;
}

.DateRangePickerInput__withBorder {
  border-radius: 8px;
}

#start_date_id_2,
#start_date_id_1 {
  border-radius: 8px 0px 0px 8px;
  text-align: center;
}

#end_date_id_2,
#end_date_id_1 {
  border-radius: 0px 8px 8px 0px;
  text-align: center;
}

.DateRangePicker_picker__fullScreenPortal {
  z-index: 50;
}

.DayPicker_portal__vertical {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
