.navbar {
  background-color: $blue_1;
  height: 56px;
}

.navbar-start {
  margin-bottom: 16px;
}

.navbar-menu .navbar-start .navbar-item {
  color: $grey_1;
}

.navbar-brand .navbar-item {
  color: $white;
}

.navbar-separator {
  width: calc(100% - 32px);
  height: 2px;
  margin-left: 16px;
  background-color: $grey_5;
}

.navbar-burger {
  margin-left: 0;
  color: $white !important;
}

.navbar-menu {
  background-color: $white;
}

.navbar-menu .navbar-item {
  display: flex;
  align-items: center;
  margin: 0px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
}

.navbar-menu .navbar-start .navbar-item:hover,
.navbar-menu .navbar-start .active-navbar-item {
  background-color: $blue_4;
  color: $grey_1;
  margin: 0px 16px;
}

.navbar-end .navbar-item {
  height: 36px;
  margin-top: 8px;
  font-size: 12px;
  color: $grey_3;
}

.navbar-end .navbar-item:hover {
  color: $grey_2;
}

.align-end {
  margin-left: auto;
}

nav {
  display: block !important;
}

.navbar-menu .navbar-item img {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.navbar-menu .navbar-item:hover img,
.navbar-menu .active-navbar-item img {
  filter: $blue_2_filter;
}

.vertical-navbar-separator {
  width: 2px;
  margin-top: 12px;
  padding: 0;
  height: 32px;
  background-color: $transparent_white;
}

.mobile-logo {
  margin-left: auto;
  margin-right: auto;
  padding-right: 52px;
}

.navbar-brand .icon-16 {
  margin-top: 9px;
  margin-right: 12px;
}

@media screen and (min-width: 1024px) {
  .is-visible-desktop {
    display: block !important;
  }
}
