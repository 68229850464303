.ContentCard {
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 2px 3px 0px $transparent_black;
  margin: 20px;
  height: calc(100% - 40px);
  padding: 24px 24px 8px 24px;
}

.ContentCard-header {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
}

.ContentCard-header .update-block {
  font-size: 12px;
  font-weight: normal;
  color: $grey_3;
  min-width: 185px;
  text-align: right;
}

.ContentCard-header .refresh-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  padding: 3px;
  border-radius: 9px;
  margin-bottom: -4px;
  cursor: pointer;
}

.ContentCard-header .refresh-icon:hover {
  background-color: lighten($black, 90);
}

.ContentCard-content {
  color: $grey_3;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

.ContentCard-chart {
  padding: 20px 0px 0px 0px;
  color: $grey_3;
  text-align: center;
}

.column {
  margin-bottom: auto;
}

.ContentCard .loader {
  border: 4px solid $blue_3;
  border-radius: 2.5em;
  border-right-color: transparent;
  border-top-color: transparent;
  height: 5em;
  width: 5em;
  margin: auto;
  animation: spinAround 800ms infinite linear;
}

.ContentCard-content .card-msg {
  font-size: 12px;
  color: $grey_3;
}

.ContentCard-header .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.ContentCard-header .subtitle {
  font-size: 18px;
  font-weight: 500;
  color: $grey_3;
  margin-left: 8px;
}

.ContentCard-content .summary-item {
  margin: 4px;
  width: 156px;
}

.ContentCard-content .summary-item-value {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.ContentCard-content .summary-item-title {
  font-size: 14px;
  font-weight: normal;
  color: $grey_1;
}

.ContentCard-content .stats-item {
  margin: 4px;
  width: 300px;
}

.ContentCard-content .stats-item-value {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.ContentCard-content .stats-item-title {
  font-size: 14px;
  font-weight: normal;
  color: $grey_1;
}

.summary-content {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 24px;
}

.apexcharts-datalabel {
  font-size: 10px;
}

@media (max-width: 768px) {
  .ContentCard {
    padding: 16px 16px 8px 16px;
    margin: 10px;
  }

  .ContentCard-header .title {
    font-size: 16px;
    font-weight: 500;
  }

  .ContentCard-header .subtitle {
    font-size: 16px;
    font-weight: 500;
  }

  .ContentCard-header .update-block {
    margin-left: auto;
    color: $grey_3;
    min-width: unset;
    text-align: right;
  }
}
