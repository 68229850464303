.ContentCard-content .EvoCard {
  display: flex;
  flex-direction: row;
}

.EvoCard .evo-block {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.EvoCard .evo-block .evo-block-label {
  color: $grey_1;
  font-size: 14px;
  font-weight: 500;
}

.EvoCard .evo-block .evo-block-value {
  color: $grey_1;
  font-size: 28px;
  font-weight: 300;
}

.EvoCard .chart-block {
  width: calc(100% - 200px);
}

img.positive-growth {
  filter: $green_filter;
}

img.negative-growth {
  filter: $red_filter;
}

@media (max-width: 1024px) {
  .EvoCard .chart-block {
    width: 100%;
  }
}
