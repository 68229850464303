.menu {
  width: 200px;
  height: 100%;
  background-color: $white;
  box-shadow: 0 5px 10px 0 $transparent_black;
  padding-top: 16px;
}

.menu-list li {
  padding: 0px 16px;
  height: 48px;
}

.menuCard {
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.menuCard a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.menuCard a:hover,
.active-menuCard a {
  background-color: $blue_4;
  border-radius: 8px;
}

.menuCard a:hover img,
.active-menuCard a img {
  filter: $blue_2_filter;
}

.menuCard img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
