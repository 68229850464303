.container-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  h5 {
    font-size: 24px;
  }

  h3 {
    font-size: 32px;
  }
}
